var mod_testimonials = [];

function mod_testimonials_start( el, interval ) {
	mod_testimonials[0] = {
		'el': $( el ),
		'timer': null,
		'interval': interval,
	}
	mod_testimonials[0]['timer'] = setInterval(function(){
		var items = mod_testimonials[0]['el'].find('blockquote');
		if (0 < items.length) {
			var cur = items.filter('.on');
			if (0 == cur.length) {
				cur = items.last();
			}
			var next = cur.next();
			if (0 == next.length) {
				next = items.first();
			}

			cur.removeClass('on');
			next.addClass('on');
			if ( cur.index() == next.index() ) {
				clearInterval( mod_testimonials[0]['timer'] );
			}
		}
	}, mod_testimonials[0]['interval']);
}

